import React from "react";
import {css} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {flexJustifyCenter, mb, mt, mv, textAlign, w100} from "@pg-design/helpers-css-strings";
import {DownloadIcon} from "@pg-design/icons-module";
import {getThemeBreakpoint} from "@pg-design/styles-strings";

export const GalleryHolderPropertyPlanButton = (props: {propertyPlan?: string; onPlanButtonClick: () => void}) => {
    return (
        <a href={props.propertyPlan} target="_blank" css={downloadButtonWrapperStyle}>
            <Button css={buttonStyle} variant="filled_primary" onClick={props.onPlanButtonClick} iconLeft={DownloadIcon}>
                Pobierz rzut
            </Button>
        </a>
    );
};

const downloadButtonWrapperStyle = css`
    @media (min-width: ${getThemeBreakpoint().md}) {
        ${flexJustifyCenter};
        ${mt(3)};
        ${mb()};
        ${w100};
        ${mv(3)};
        ${textAlign("center")};
    }
`;

const buttonStyle = css`
    width: 100%;

    @media (min-width: ${getThemeBreakpoint().md}) {
        width: auto;
    }
`;
import React from "react";
import {css} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {flexJustifyCenter, mb, mt, mv, textAlign, w100} from "@pg-design/helpers-css-strings";
import {DownloadIcon} from "@pg-design/icons-module";
import {getThemeBreakpoint} from "@pg-design/styles-strings";

export const GalleryHolderPropertyPlanButton = (props: {propertyPlan?: string; onPlanButtonClick: () => void}) => {
    return (
        <a href={props.propertyPlan} target="_blank" css={downloadButtonWrapperStyle}>
            <Button css={buttonStyle} variant="filled_primary" onClick={props.onPlanButtonClick} iconLeft={DownloadIcon}>
                Pobierz rzut
            </Button>
        </a>
    );
};

const downloadButtonWrapperStyle = css`
    @media (min-width: ${getThemeBreakpoint().md}) {
        ${flexJustifyCenter};
        ${mt(3)};
        ${mb()};
        ${w100};
        ${mv(3)};
        ${textAlign("center")};
    }
`;

const buttonStyle = css`
    width: 100%;

    @media (min-width: ${getThemeBreakpoint().md}) {
        width: auto;
    }
`;
