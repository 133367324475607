import React, {Fragment, Suspense} from "react";

import {useIsMounted} from "@pg-mono/hooks";

interface IProps {
    children: React.ReactNode;
}

export const FragmentFallbackSuspense: React.FC<IProps> = (props) => {
    const isMounted = useIsMounted();

    return isMounted ? <Suspense fallback={<Fragment />}>{props.children}</Suspense> : null;
};
import React, {Fragment, Suspense} from "react";

import {useIsMounted} from "@pg-mono/hooks";

interface IProps {
    children: React.ReactNode;
}

export const FragmentFallbackSuspense: React.FC<IProps> = (props) => {
    const isMounted = useIsMounted();

    return isMounted ? <Suspense fallback={<Fragment />}>{props.children}</Suspense> : null;
};
