import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const DownloadIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="m6.131 5.027.378.378L5 6.913 3.491 5.405l.378-.378.858.859V1h.533v4.898l.871-.871ZM1.533 8.467h6.933V9H1.533v-.533ZM1.533 6.333H1V9h.533V6.333ZM8.467 6.333H9V9h-.534V6.333Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const DownloadIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="m6.131 5.027.378.378L5 6.913 3.491 5.405l.378-.378.858.859V1h.533v4.898l.871-.871ZM1.533 8.467h6.933V9H1.533v-.533ZM1.533 6.333H1V9h.533V6.333ZM8.467 6.333H9V9h-.534V6.333Z" />
        </SvgIcon>
    );
};
