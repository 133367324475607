import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export enum GAAuthEventName {
    SIGN_UP = "sign_up",
    LOGIN = "login"
}

export enum GAAuthEventCategory {
    REGISTRATION_FORM = "registration_form",
    LOGIN_FORM = "login_form",
    LEAD_FORM = "lead_form",
    GOOGLE_ONE_TAP = "google_one_tap"
}

enum GAAuthEventLabel {
    GOOGLE = "google",
    FACEBOOK = "facebook",
    EMAIL = "email"
}

const googleAuthEvent = (event: GAAuthEventName, eventCategory: GAAuthEventCategory) => {
    hitGoogleTagManager({
        event,
        event_category: eventCategory,
        event_label: GAAuthEventLabel.GOOGLE
    });
};

const facebookAuthEvent = (event: GAAuthEventName, eventCategory: Exclude<GAAuthEventCategory, GAAuthEventCategory.GOOGLE_ONE_TAP>) => {
    hitGoogleTagManager({
        event,
        event_category: eventCategory,
        event_label: GAAuthEventLabel.FACEBOOK
    });
};

const emailAuthEvent = (event: GAAuthEventName, eventCategory: Exclude<GAAuthEventCategory, GAAuthEventCategory.GOOGLE_ONE_TAP>) => {
    hitGoogleTagManager({
        event,
        event_category: eventCategory,
        event_label: GAAuthEventLabel.EMAIL
    });
};

export const authAnalytics = {
    gtm: {
        googleAuthEvent,
        facebookAuthEvent,
        emailAuthEvent
    }
};
import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export enum GAAuthEventName {
    SIGN_UP = "sign_up",
    LOGIN = "login"
}

export enum GAAuthEventCategory {
    REGISTRATION_FORM = "registration_form",
    LOGIN_FORM = "login_form",
    LEAD_FORM = "lead_form",
    GOOGLE_ONE_TAP = "google_one_tap"
}

enum GAAuthEventLabel {
    GOOGLE = "google",
    FACEBOOK = "facebook",
    EMAIL = "email"
}

const googleAuthEvent = (event: GAAuthEventName, eventCategory: GAAuthEventCategory) => {
    hitGoogleTagManager({
        event,
        event_category: eventCategory,
        event_label: GAAuthEventLabel.GOOGLE
    });
};

const facebookAuthEvent = (event: GAAuthEventName, eventCategory: Exclude<GAAuthEventCategory, GAAuthEventCategory.GOOGLE_ONE_TAP>) => {
    hitGoogleTagManager({
        event,
        event_category: eventCategory,
        event_label: GAAuthEventLabel.FACEBOOK
    });
};

const emailAuthEvent = (event: GAAuthEventName, eventCategory: Exclude<GAAuthEventCategory, GAAuthEventCategory.GOOGLE_ONE_TAP>) => {
    hitGoogleTagManager({
        event,
        event_category: eventCategory,
        event_label: GAAuthEventLabel.EMAIL
    });
};

export const authAnalytics = {
    gtm: {
        googleAuthEvent,
        facebookAuthEvent,
        emailAuthEvent
    }
};
