// Hook informs whether the HTML element was visible in the viewport
import {useEffect, useRef} from "react";

interface IParams {
    ids: string[];
    once?: boolean; // call `onElementVisible` callback only once - regardless of the number of views
    onElementVisible: (id: string, entry: IntersectionObserverEntry) => void;
    threshold?: number;
}

export const useElementWasVisible = (params: IParams) => {
    const seen = useRef<string[]>([]);

    useEffect(() => {
        if (!("IntersectionObserver" in window)) {
            return;
        }

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const id = entry.target.getAttribute("data-id") as string;

                    if (entry.isIntersecting) {
                        if (params.once) {
                            if (!seen.current.includes(id)) {
                                seen.current = seen.current.concat(id);

                                params.onElementVisible(id, entry);
                            }

                            return;
                        } else {
                            params.onElementVisible(id, entry);
                        }
                    }
                });
            },
            {threshold: [params.threshold ? params.threshold : 1]} // `1` means full element was visible
        );

        const elements: Element[] = [];

        params.ids.forEach((id) => {
            const element = document.querySelector(`[data-id="${id}"]`);

            if (element) {
                observer.observe(element);

                elements.push(element);
            }
        });

        return () => {
            elements.forEach((element) => observer.unobserve(element));
        };
    }, [JSON.stringify(params.ids)]);
};
// Hook informs whether the HTML element was visible in the viewport
import {useEffect, useRef} from "react";

interface IParams {
    ids: string[];
    once?: boolean; // call `onElementVisible` callback only once - regardless of the number of views
    onElementVisible: (id: string, entry: IntersectionObserverEntry) => void;
    threshold?: number;
}

export const useElementWasVisible = (params: IParams) => {
    const seen = useRef<string[]>([]);

    useEffect(() => {
        if (!("IntersectionObserver" in window)) {
            return;
        }

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const id = entry.target.getAttribute("data-id") as string;

                    if (entry.isIntersecting) {
                        if (params.once) {
                            if (!seen.current.includes(id)) {
                                seen.current = seen.current.concat(id);

                                params.onElementVisible(id, entry);
                            }

                            return;
                        } else {
                            params.onElementVisible(id, entry);
                        }
                    }
                });
            },
            {threshold: [params.threshold ? params.threshold : 1]} // `1` means full element was visible
        );

        const elements: Element[] = [];

        params.ids.forEach((id) => {
            const element = document.querySelector(`[data-id="${id}"]`);

            if (element) {
                observer.observe(element);

                elements.push(element);
            }
        });

        return () => {
            elements.forEach((element) => observer.unobserve(element));
        };
    }, [JSON.stringify(params.ids)]);
};
