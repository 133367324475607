import {hitGoogleTagManager} from "./init_google_tag_manager";

export const gtmFormRegister = () => {
    hitGoogleTagManager({event: "form_register"});
};
import {hitGoogleTagManager} from "./init_google_tag_manager";

export const gtmFormRegister = () => {
    hitGoogleTagManager({event: "form_register"});
};
