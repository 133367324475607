import React, {Fragment} from "react";
import {css} from "@emotion/react";

import {Badge, BadgeVariantsUnionType} from "@pg-design/badge-module";
import {getThemeVariable} from "@pg-design/styles-strings";

export interface IGalleryPicture {
    imgAlt?: string;
    onClick: () => void;
    srcSm?: string;
    srcMd?: string;
    srcLg?: string;
    tags?: {
        label: string;
        variant: BadgeVariantsUnionType;
        icon?: (props: {fill: string; className: string; size?: string}) => React.ReactElement;
    }[];
    onLoad?: () => void;
}

export const GalleryPicture = (props: IGalleryPicture) => {
    return (
        <div css={wrapper}>
            {props.tags && (
                <div css={tagContainer}>
                    {props.tags.map((tag, index) => (
                        <Badge variant={tag.variant} icon={tag.icon} iconSize="1.5" key={index}>
                            {tag.label}
                        </Badge>
                    ))}
                </div>
            )}
            <picture css={galleryImageHolder}>
                {props.srcLg && <source media="(min-width: 1920px)" srcSet={props.srcLg} />}
                {props.srcMd && (
                    <Fragment>
                        <source media="(min-width: 1400px)" srcSet={props.srcMd} />
                        <source media="(min-width: 480px)" srcSet={props.srcMd} />
                    </Fragment>
                )}
                {props.srcSm && (
                    <Fragment>
                        <source media="(min-width: 1024px)" srcSet={props.srcSm} />
                        <source media="(min-width: 768px)" srcSet={props.srcSm} />
                    </Fragment>
                )}
                <img src={props.srcSm} alt={props.imgAlt} loading="lazy" onClick={props.onClick} onLoad={props.onLoad} />
            </picture>
        </div>
    );
};

const galleryImageHolder = css`
    display: block;

    > img {
        margin-bottom: 2rem;
        vertical-align: middle;
        width: 100%;
        cursor: pointer;
    }
`;

const wrapper = css`
    position: relative;
`;

const tagContainer = css`
    position: absolute;
    top: ${getThemeVariable("globals.baseUnit")};
    left: ${getThemeVariable("globals.baseUnit")};
    display: flex;
    gap: ${getThemeVariable("globals.baseUnit")};
`;
import React, {Fragment} from "react";
import {css} from "@emotion/react";

import {Badge, BadgeVariantsUnionType} from "@pg-design/badge-module";
import {getThemeVariable} from "@pg-design/styles-strings";

export interface IGalleryPicture {
    imgAlt?: string;
    onClick: () => void;
    srcSm?: string;
    srcMd?: string;
    srcLg?: string;
    tags?: {
        label: string;
        variant: BadgeVariantsUnionType;
        icon?: (props: {fill: string; className: string; size?: string}) => React.ReactElement;
    }[];
    onLoad?: () => void;
}

export const GalleryPicture = (props: IGalleryPicture) => {
    return (
        <div css={wrapper}>
            {props.tags && (
                <div css={tagContainer}>
                    {props.tags.map((tag, index) => (
                        <Badge variant={tag.variant} icon={tag.icon} iconSize="1.5" key={index}>
                            {tag.label}
                        </Badge>
                    ))}
                </div>
            )}
            <picture css={galleryImageHolder}>
                {props.srcLg && <source media="(min-width: 1920px)" srcSet={props.srcLg} />}
                {props.srcMd && (
                    <Fragment>
                        <source media="(min-width: 1400px)" srcSet={props.srcMd} />
                        <source media="(min-width: 480px)" srcSet={props.srcMd} />
                    </Fragment>
                )}
                {props.srcSm && (
                    <Fragment>
                        <source media="(min-width: 1024px)" srcSet={props.srcSm} />
                        <source media="(min-width: 768px)" srcSet={props.srcSm} />
                    </Fragment>
                )}
                <img src={props.srcSm} alt={props.imgAlt} loading="lazy" onClick={props.onClick} onLoad={props.onLoad} />
            </picture>
        </div>
    );
};

const galleryImageHolder = css`
    display: block;

    > img {
        margin-bottom: 2rem;
        vertical-align: middle;
        width: 100%;
        cursor: pointer;
    }
`;

const wrapper = css`
    position: relative;
`;

const tagContainer = css`
    position: absolute;
    top: ${getThemeVariable("globals.baseUnit")};
    left: ${getThemeVariable("globals.baseUnit")};
    display: flex;
    gap: ${getThemeVariable("globals.baseUnit")};
`;
