import React, {ReactNode, useCallback, useMemo} from "react";
import {css, Theme} from "@emotion/react";

import {gridG} from "@pg-design/helpers-css";

import {IGalleryImage} from "../types/IGalleryImage";
import {GalleryPicture} from "./GalleryPicture";

interface IProps {
    getImageAlt: (index: number, isConstructionImage?: boolean) => string;
    images: IGalleryImage[];
    onImageClick: (index: number) => void;
    lastTile?: ReactNode;
}

export const GalleryPicturesGrid = (props: IProps) => {
    const renderGalleryPicture = useCallback((index: number, image: IGalleryImage) => {
        return <GalleryPicture key={index} onClick={() => props.onImageClick(index)} {...image} />;
    }, []);

    if (!props.images.length) {
        return null;
    }

    const {evenImages, oddImages} = useMemo(() => {
        return props.images.reduce<{oddImages: IGalleryImage[]; evenImages: IGalleryImage[]}>(
            (acc, image, index) => {
                const imageWithAlt = {...image, imgAlt: props.getImageAlt(index)};

                index % 2 === 0 ? acc.evenImages.push(imageWithAlt) : acc.oddImages.push(imageWithAlt);
                return acc;
            },
            {evenImages: [], oddImages: []}
        );
    }, [props.images]);

    // yes, this placement calculation is naive, but this feature is hot... if that bothers anyone, calculate combined images height or whatever
    const lastTilePlacement = evenImages.length > oddImages.length ? "odd" : "even";

    return (
        <div css={galleryRow}>
            <div css={galleryColumn}>
                {evenImages.map((image, index) => renderGalleryPicture(index * 2, image))}
                {lastTilePlacement === "even" && props.lastTile && <div css={lastTile}>{props.lastTile}</div>}
            </div>

            <div css={galleryColumn}>
                {oddImages.map((image, index) => renderGalleryPicture(index * 2 + 1, image))}
                {lastTilePlacement === "odd" && props.lastTile && <div css={lastTile}>{props.lastTile}</div>}
            </div>
        </div>
    );
};

const galleryRow = (theme: Theme) => css`
    display: grid;

    @media (min-width: ${theme.breakpoints.sm}) {
        ${gridG(2)}
        grid-template-columns: 1fr 1fr;
    }
`;

const galleryColumn = css`
    max-width: 100%;
`;

const lastTile = css``;
import React, {ReactNode, useCallback, useMemo} from "react";
import {css, Theme} from "@emotion/react";

import {gridG} from "@pg-design/helpers-css";

import {IGalleryImage} from "../types/IGalleryImage";
import {GalleryPicture} from "./GalleryPicture";

interface IProps {
    getImageAlt: (index: number, isConstructionImage?: boolean) => string;
    images: IGalleryImage[];
    onImageClick: (index: number) => void;
    lastTile?: ReactNode;
}

export const GalleryPicturesGrid = (props: IProps) => {
    const renderGalleryPicture = useCallback((index: number, image: IGalleryImage) => {
        return <GalleryPicture key={index} onClick={() => props.onImageClick(index)} {...image} />;
    }, []);

    if (!props.images.length) {
        return null;
    }

    const {evenImages, oddImages} = useMemo(() => {
        return props.images.reduce<{oddImages: IGalleryImage[]; evenImages: IGalleryImage[]}>(
            (acc, image, index) => {
                const imageWithAlt = {...image, imgAlt: props.getImageAlt(index)};

                index % 2 === 0 ? acc.evenImages.push(imageWithAlt) : acc.oddImages.push(imageWithAlt);
                return acc;
            },
            {evenImages: [], oddImages: []}
        );
    }, [props.images]);

    // yes, this placement calculation is naive, but this feature is hot... if that bothers anyone, calculate combined images height or whatever
    const lastTilePlacement = evenImages.length > oddImages.length ? "odd" : "even";

    return (
        <div css={galleryRow}>
            <div css={galleryColumn}>
                {evenImages.map((image, index) => renderGalleryPicture(index * 2, image))}
                {lastTilePlacement === "even" && props.lastTile && <div css={lastTile}>{props.lastTile}</div>}
            </div>

            <div css={galleryColumn}>
                {oddImages.map((image, index) => renderGalleryPicture(index * 2 + 1, image))}
                {lastTilePlacement === "odd" && props.lastTile && <div css={lastTile}>{props.lastTile}</div>}
            </div>
        </div>
    );
};

const galleryRow = (theme: Theme) => css`
    display: grid;

    @media (min-width: ${theme.breakpoints.sm}) {
        ${gridG(2)}
        grid-template-columns: 1fr 1fr;
    }
`;

const galleryColumn = css`
    max-width: 100%;
`;

const lastTile = css``;
